export const listToTree = (arr:any = []) => {
  let map:any = {}, node, res:any = [], i;
  for (i = 0; i < arr.length; i++) {
    map[arr[i]._id] = i;
    arr[i].list = [];
  }
  for (i = 0; i < arr.length; i++) {
    node = arr[i];
    if (node.parent_id !== "0") {
      arr[map[node.parent_id]].list.push(node);
    }
    else {
      res.push(node);
    }
  }
  return res;
};
export const listToInput = (arr:any = []) => {
  arr = listToTree(arr);
  let map:any = [], i, j;
  for (i = 0; i < arr.length; i++) {
    map.push({_id:arr[i]._id, _p_id:0, name:arr[i].name});
    if(arr[i].list.length > 0){
      for (j = 0; j < arr[i].list.length; j++) {
        map.push({_id:arr[i].list[j]._id, _p_id:arr[i]._id, name:(arr[i].name +" => "+arr[i].list[j].name)});
      }
    }
    else{
      //map.push({_id:arr[i]._id, name:arr[i].name});
    }
  }
  return map;
};

export const listToBands = (arr:any = []) => {
  let map:any = [], i;
  for (i = 0; i < arr.length; i++) {
    map.push({_id:arr[i]._id, name:arr[i].band});
  }
  return map;
};

export const CustomDateFormat = (dateVal:string) => {
  if (dateVal !== "") {
    let _dateVal = dateVal.split("T");
    if (!_dateVal[0]) {
      _dateVal = dateVal.split(" ");
    }
    if (_dateVal[0]) {
      _dateVal = _dateVal[0].split("-");
      if (_dateVal[2]) {
        dateVal = (_dateVal[0])+'-'+((_dateVal[1]))+'-'+(_dateVal[2]);
      }
    }
  }
  const d = new Date(dateVal);
  return d.getUTCDate() + " " + (d.toLocaleString('default', { month: 'long' })
  ) + ", " + d.getUTCFullYear()
};

